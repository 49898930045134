import Button from '../../../components/button/button';
import Container from '../../../components/container/container';
import Typography from '../../../components/title/typography';
import { ReactComponent as BlueFallSVG } from '../../../svg/blue_fall.svg';
import styles from './about.module.css';
import { statistic } from './statistic';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  const renderStatistic = statistic.map((item, index) => (
    <div
      key={index}
      className={classNames([
        styles.statisticBox,
        index === 0 && styles.statisticBoxPositive,
        index === 3 && styles.statisticBoxNegative,
      ])}
    >
      <Typography
        colors={['grey-dark']}
        className={styles.statisticBoxText}
        size={48}
        weight="500"
      >
        {item.count}
      </Typography>
      <Typography
        className={styles.statisticBoxText}
        size={16}
        colors={['grey-dark']}
      >
        {item.title}
      </Typography>
    </div>
  ));

  return (
    <section id="about" className={styles.about}>
      <BlueFallSVG className={styles.blueFallSvg} />
      <Container className={styles.container}>
        <div className={styles.statistic}>{renderStatistic}</div>
        <div className={styles.aboutInformation}>
          <Typography
            upercase
            type="title"
            size={48}
            colors={['green', 'grey-dark', 'blue']}
          >
            {t('aboutSection.title')}
          </Typography>
          <Typography className={styles.description} size={16}>
            {t('aboutSection.description')}
          </Typography>
          <Button>{t('readMore')}</Button>
        </div>
      </Container>
    </section>
  );
};

export default About;
