import { routes } from '../constants/routes';
import Landing from '../pages/landing';
import RootLayout from './rootLayout/rootLayout';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: routes.main,
        element: <Landing />,
      },
    ],
  },
]);
