import styles from './container.module.css';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container = ({ children, className = '' }: ContainerProps) => {
  return (
    <div className={classNames([styles.container, className])}>{children}</div>
  );
};

export default Container;
