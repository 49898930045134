import styles from './button.module.css';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface ButtonProps {
  children: ReactNode;
  className?: string;
  size?: 'small' | 'default';
}

const Button = ({ children, className, size = 'default' }: ButtonProps) => {
  return (
    <button
      className={classNames([styles.button, className, styles[`size-${size}`]])}
    >
      {children}
    </button>
  );
};

export default Button;
