import { navigation } from '../../constants/navigation';
import { ReactComponent as LogoSVG } from '../../svg/logo.svg';
import Button from '../button/button';
import Container from '../container/container';
import styles from './header.module.css';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();
  const [isActiveSection, setActiveSection] = useState('home');

  useEffect(() => {
    onScrollSpy();
  }, []);

  const onScrollSpy = () => {
    const sections = document.querySelectorAll('.sections section');

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries)
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
      },
      {
        rootMargin: '-50% 0px',
      }
    );
    for (let i = 0; i < sections.length; i++) observer.observe(sections[i]);
  };

  const scrollTo = (id: string) => {
    const section = document.getElementById(id);

    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const renderNavigation = Object.keys(navigation).map((item, index) => (
    <div
      key={item}
      className={classNames([
        styles.link,
        isActiveSection === item ? styles.linkActive : styles.linkDisactive,
      ])}
      onClick={() => scrollTo(item)}
    >
      {navigation[item]}
    </div>
  ));

  return (
    <header className={styles.header}>
      <Container className={styles.container}>
        <LogoSVG className={styles.logo} />
        <div className={styles.navigation}>
          {renderNavigation} <Button size="small">{t('haveAnIdea')}</Button>
        </div>
      </Container>
    </header>
  );
};

export default Header;
