import styles from './typography.module.css';
import classNames from 'classnames';
import { CSSProperties } from 'react';

type Colors = 'green' | 'blue' | 'grey-dark';

interface TypographyProps {
  children: string;
  size?: number;
  type?: 'title' | 'text';
  weight?: '300' | '400' | '500';
  colors?: Colors[];
  upercase?: boolean;
  className?: string;
}

const Typography = ({
  children,
  size = 14,
  type = 'text',
  weight = '400',
  colors = [],
  upercase = false,
  className = '',
}: TypographyProps) => {
  const splitText = children.split(' ').map((item, i) => (
    <span key={i} className={styles[`color-${colors[i]}`]}>
      {item}{' '}
    </span>
  ));

  const style: CSSProperties = {
    textTransform: upercase ? 'uppercase' : 'none',
    fontSize: `${size}px`,
    fontWeight: weight,
  };

  return (
    <div
      style={style}
      className={classNames([
        styles.typography,
        className,
        styles[`color-${colors[0]}`],
      ])}
    >
      {type === 'title' ? splitText : children}
    </div>
  );
};

export default Typography;
