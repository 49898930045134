import About from './about/about';
import Home from './home/home';
import Team from './team/team';

const Landing = () => {
  return (
    <>
      <Home />
      <About />
      <Team />
    </>
  );
};

export default Landing;
