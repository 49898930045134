import ArduinoSVG from '../../../svg/languages/arduino.svg';
import CssSVG from '../../../svg/languages/css.svg';
import FigmaSVG from '../../../svg/languages/figma.svg';
import GitSVG from '../../../svg/languages/git.svg';
import HtmlSVG from '../../../svg/languages/html.svg';
import JsSVG from '../../../svg/languages/js.svg';
import NodeSVG from '../../../svg/languages/node.svg';
import ReactSVG from '../../../svg/languages/react.svg';

export const languages = [
  {
    key: 'js',
    icon: JsSVG,
  },
  {
    key: 'html',
    icon: HtmlSVG,
  },
  {
    key: 'css',
    icon: CssSVG,
  },
  {
    key: 'node',
    icon: NodeSVG,
  },
  {
    key: 'react',
    icon: ReactSVG,
  },
  {
    key: 'figma',
    icon: FigmaSVG,
  },
  {
    key: 'git',
    icon: GitSVG,
  },
  {
    key: 'arduino',
    icon: ArduinoSVG,
  },
];
