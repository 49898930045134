import i18n from '../i18next/i18next';

export const navigation: { [key: string]: string } = {
  home: i18n.t('navigation.home'),
  about: i18n.t('navigation.about'),
  services: i18n.t('navigation.services'),
  portfolio: i18n.t('navigation.portfolio'),
  feedback: i18n.t('navigation.feedback'),
  faq: i18n.t('navigation.faq'),
};
