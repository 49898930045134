import Button from '../../../components/button/button';
import Container from '../../../components/container/container';
import Typography from '../../../components/title/typography';
import { ReactComponent as GreenFallSVG } from '../../../svg/green_fall.svg';
import styles from './home.module.css';
import { languages } from './languages';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  const renderLanguages = languages.map((item) => (
    <img
      key={item.key}
      className={styles.icon}
      alt={item.key}
      src={item.icon}
    />
  ));

  return (
    <section id="home" className={styles.home}>
      <GreenFallSVG className={styles.sectionGreenFall} />
      <Container>
        <div className={styles.languages}>{renderLanguages}</div>
        <Typography
          upercase
          colors={['green', 'grey-dark', 'grey-dark', 'blue']}
          weight="500"
          type="title"
          size={48}
        >
          {t('homeSection.title')}
        </Typography>
        <Typography className={styles.description} size={16}>
          {t('homeSection.description')}
        </Typography>
        <Button className={styles.detailsButton}>{t('details')}</Button>
      </Container>
    </section>
  );
};

export default Home;
