import i18n from '../../../i18next/i18next';

export const statistic: {
  count: string;
  title: string;
}[] = [
  {
    count: '50+',
    title: i18n.t('aboutSection.statistic.project'),
  },
  {
    count: '100K',
    title: i18n.t('aboutSection.statistic.development'),
  },
  {
    count: '10',
    title: i18n.t('aboutSection.statistic.startups'),
  },
  {
    count: '30',
    title: i18n.t('aboutSection.statistic.developers'),
  },
];
