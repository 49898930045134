import Container from '../../../components/container/container';
import Typography from '../../../components/title/typography';
import styles from './team.module.css';
import { useTranslation } from 'react-i18next';

const Team = () => {
  const { t } = useTranslation();

  return (
    <section id="team">
      <Container>
        <Typography type="title" colors={['green', 'blue']} size={48} upercase>
          {t('teamSection.title')}
        </Typography>
        <Typography colors={['grey-dark']} size={16}>
          {t('teamSection.description')}
        </Typography>
        <div className={styles.missionBox}>
          <Typography
            type="title"
            colors={['green', 'blue']}
            size={24}
            upercase
          >
            {t('teamSection.missionTitle')}
          </Typography>
          <Typography colors={['grey-dark']} size={16}>
            {t('teamSection.missionDescription')}
          </Typography>
        </div>
      </Container>
    </section>
  );
};

export default Team;
