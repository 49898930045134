import Header from '../../components/header/header';
import { ReactComponent as DarkModeSVG } from '../../svg/darkMode.svg';
import { ReactComponent as LightModeSVG } from '../../svg/lightMode.svg';
import styles from './rootLayout.module.css';
import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

const RootLayout = () => {
  const [theme, setTheme] = useState<string>('light');

  useEffect(() => {
    const theme = localStorage.getItem('theme');

    if (theme) {
      document.body.setAttribute('data-theme', theme);
      setTheme(theme);
    }
  }, []);

  const toggleDarkMode = useCallback(() => {
    const currentTheme = document.body.getAttribute('data-theme');

    const theme = currentTheme === 'dark' ? 'light' : 'dark';

    setTheme(theme);

    localStorage.setItem('theme', theme);

    document.body.setAttribute('data-theme', theme);
  }, []);

  return (
    <>
      <Header />
      <main className="sections">
        <Outlet />
        <section id="services" style={{ height: '100vh' }}>
          Section 3
        </section>
        <section id="portfolio" style={{ height: '100vh' }}>
          Section 4
        </section>
      </main>

      <div className={styles.themeMode} onClick={toggleDarkMode}>
        {theme === 'dark' ? <LightModeSVG /> : <DarkModeSVG />}
      </div>
    </>
  );
};

export default RootLayout;
